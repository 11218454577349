@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.cuisineCard {
  flex: 1 1 30%;
  margin: 10px;
  max-width: 100%;
  text-align: center;
}

@media (max-width: 768px) {
  .card {
    flex: 1 1 80%;
    max-width: 80%;
  }
}

.fade-in {
  animation: fadeIn 2s forwards;
}

.fade-out {
  animation: fadeOut 2s forwards;
}

.donotcross {
  overflow: hidden;
  align-items: center;
  background: #052e16;
  color: white;
  height: 2em;
  line-height: 2em;

  &:before,
  &:after {
    background: white;
    padding: 50px 0;
    height: 0;
    transform: rotate(45deg);
  }
}

/* Media query for mobile devices */
@media (max-width: 767px) {
  .donotcross {
    &:before,
    &:after {
      padding: 10px 0; /* Thinner strips for mobile */
    }
  }
}

.divider {
  display: flex;

  &:before,
  &:after {
    content: "";
    flex: 1;
  }
}
